import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import SEOPage from '../components/SEO/Page'

import Header from '../components/Header'
import Form from '../components/Form'

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEOPage
        title="Local freelancing platform for creatives"
        location={location}
        description="Allocate is a new platform for connecting creative freelancers with local businesses. Launching 2019.
        "
      />

      <div className="lg:flex lg:h-screen">
        <div className="bg-purple text-white lg:w-1/2 p-10 md:p-16 xl:p-32">
          <div className="h-full w-full overflow-y-auto">
            <Header />
          </div>
        </div>
        <div className="bg-white lg:w-1/2 p-10 md:p-16 xl:p-32">
          <div className="h-full w-full overflow-y-auto">
            <h2 className="text-xl md:text-2xl leading-tight font-bold mb-8 md:mb-16">
              Freelancer? Register your interest
            </h2>
            <Form />
          </div>
        </div>
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
