import React from 'react'
import axios from 'axios'
import { withFormik, Form, Field } from 'formik'

const Row = ({ children }) => (
  <div className="flex flex-wrap -ml-3">{children}</div>
)

const Column = ({ size, children }) => (
  <div className={`w-full lg:w-${size} pl-3 mb-6`}>{children}</div>
)

const Label = ({ text, htmlFor }) => (
  <label
    className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
    htmlFor={htmlFor}
  >
    {text}
  </label>
)

const Input = ({ id, name, type, placeholder }) => (
  <Field
    className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey-light focus:shadow"
    type={type}
    id={id}
    name={name}
    placeholder={placeholder}
    required
  />
)

const Dropdown = ({ id, name, children }) => (
  <div className="relative">
    <Field
      component="select"
      name={name}
      className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-grey-light focus:shadow"
      id={id}
      required
    >
      {children}
    </Field>
    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
      <svg
        className="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
)

const NewsletterForm = ({ values, errors, isSubmitting, status }) => {
  return (
    <>
      {/* Error Message */}
      {errors.submit && <div className="text-red mb-8">{errors.submit}</div>}
      {/* Success Message */}
      {status && status.success && (
        <div className="rte">
          <p className="font-medium text-xl">
            Thanks for registering your interest.
          </p>
          <p>Stay tuned for periodic updates on our progress.</p>
        </div>
      )}
      {!(status && status.success) && (
        <Form>
          <Row>
            <Column size="1/2">
              <Label text="First Name" htmlFor="first-name" />
              <Input
                id="first-name"
                name="firstname"
                placeholder="Walt"
                type="text"
              />
            </Column>
            <Column size="1/2">
              <Label text="Last Name" htmlFor="last-name" />
              <Input
                id="last-name"
                name="lastname"
                placeholder="Disney"
                type="text"
              />
            </Column>
            <Column size="1/2">
              <Label text="Email" htmlFor="email" />
              <Input
                id="email"
                name="email"
                placeholder="walt@disney.com"
                type="email"
              />
            </Column>
            <Column size="1/2">
              <Label text="Phone" htmlFor="phone" />
              <Input
                id="phone"
                name="phone"
                placeholder="03 421 6601"
                type="tel"
              />
            </Column>
            <Column size="full">
              <Label text="Portfolio Address" htmlFor="portfolio-address" />
              <Input
                id="portfolio-address"
                name="portfolio"
                placeholder="www.disney.com"
                type="url"
              />
            </Column>
            <Column size="2/3">
              <Label text="Specialty" htmlFor="specialty" />
              <Dropdown id="specialty" name="specialty">
                <optgroup label="Design">
                  <option value="graphic, branding & design">
                    Graphics, branding & design
                  </option>
                  <option value="UI/UX design">UI/UX design</option>
                </optgroup>
                <optgroup label="Development">
                  <option value="Web development">Web development</option>
                  <option value="Mobile development">Mobile development</option>
                </optgroup>
                <optgroup label="Sales & marketing">
                  <option value="Digital marketing">Digital marketing</option>
                  <option value="Public relations">Public relations</option>
                  <option value="Copywriting">Copywriting</option>
                </optgroup>
                <optgroup label="Other">
                  <option value="Photography">Photography</option>
                  <option value="Video production">Video production</option>
                </optgroup>
              </Dropdown>
              {/* https://www.upwork.com/i/freelancer-categories-all/ */}
            </Column>
            <Column size="1/3">
              <Label text="Experience" htmlFor="experience" />
              <Dropdown id="experience" name="experience">
                <option value="world class">World class</option>
                <option value="senior">Senior</option>
                <option value="intermediate">Intermediate</option>
                <option value="junior">Junior</option>
              </Dropdown>
            </Column>
            <Column size="1/2">
              <Label text="City" htmlFor="city" />
              <Dropdown id="city" name="city">
                <option value="auckland">Auckland</option>
                <option value="wellington">Wellington</option>
                <option value="christchurch">Christchurch</option>
              </Dropdown>
            </Column>
            <Column size="1/2">
              <Label text="Standard Rate ($)" htmlFor="rate" />
              <Input id="rate" name="rate" placeholder="85" type="number" />
            </Column>
          </Row>

          <button className="button mt-4" type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </Form>
      )}
    </>
  )
}

const FormikNewsletterForm = withFormik({
  mapPropsToValues: values => ({
    firstname: values.firstname || '',
    lastname: values.lastname || '',
    email: values.email || '',
    phone: values.phone || '',
    portfolio: values.portfolio || '',
    specialty: values.specialty || 'graphic, branding & design',
    experience: values.experience || 'world class',
    city: values.city || 'auckland',
    rate: values.rate || 0,
  }),
  validate: values => {
    const errors = {}
    // if (!values.firstname) errors.firstname = 'Required'
    return errors
  },
  handleSubmit: async (
    formData,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const cmPostData = {
        Resubscribe: false,
        ConsentToTrack: 'Yes',

        EmailAddress: formData.email,
        Name: `${formData.firstname} ${formData.lastname}`,
        CustomFields: [
          {
            Key: 'Phone',
            Value: formData.phone,
          },
          {
            Key: 'Portfolio',
            Value: formData.portfolio,
          },
          {
            Key: 'Specialty',
            Value: formData.specialty,
          },
          {
            Key: 'Experience',
            Value: formData.experience,
          },
          {
            Key: 'City',
            Value: formData.city,
          },
          {
            Key: 'Rate',
            Value: formData.rate,
          },
        ],
      }
      await axios.post(
        'https://allocate001.netlify.com/.netlify/functions/cm',
        cmPostData
      )
      resetForm()
      setStatus({ success: true })
    } catch (error) {
      // Set errors.submit message
      setErrors({
        submit: 'There was an error submitting the form, please try again.',
      })
      // Erasing error message after 5s
      setTimeout(() => {
        setErrors({ submit: '' })
      }, 5000)
    } finally {
      setSubmitting(false)
    }
  },
})(NewsletterForm)

export default FormikNewsletterForm
