import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

// import Img from 'gatsby-image'

const TickItem = ({ text }) => (
  <li className="flex items-center">
    {' '}
    <svg
      className="text-white fill-current h-5 w-5 md:h-6 md:w-6 mr-3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>{' '}
    {text}
  </li>
)

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "icon.png" }) {
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <header className="md:h-full">
        {/* <Img
          fixed={data.logo.childImageSharp.fixed}
          alt="Allocate"
          className="inline-block mb-4"
        /> */}
        <h1 className="text-xl md:text-4xl font-medium leading-tight mb-6">
          Allocate is a new platform for connecting creative freelancers with
          local businesses. Launching 2019.
        </h1>
        <div className="text-sm md:text-lg rte md:w-4/5 mb-8 mb:mb-16">
          <p>
            <strong className="font-semibold">
              Allocate helps creative freelancers to:
            </strong>
          </p>
          <ul className="list-reset">
            <TickItem text="Find more work" />
            <TickItem text="Manage work easier" />
            <TickItem text="Get paid faster" />
            <TickItem text="Reduce admin" />
          </ul>
          <p>
            We're looking to speak with those in Auckland, Wellington or
            Christchurch who would like to be part of our alpha or beta release.
          </p>
          <p>
            Please register your interest using the following form or{' '}
            <a href="mailto:alex@batch.nz">reach out</a> for more information
            about what we're doing.
          </p>
        </div>
        <a
          className="group inline-block h-6 md:h-8 w-6 md:w-8"
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          title="Visit us on Instagram"
        >
          <svg
            className="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 0 0 1.384 2.126A5.868 5.868 0 0 0 4.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 0 0 2.126-1.384 5.86 5.86 0 0 0 1.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 0 0-1.384-2.126A5.847 5.847 0 0 0 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 0 1-.899 1.382 3.744 3.744 0 0 1-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 0 1-1.379-.899 3.644 3.644 0 0 1-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 1 0 0-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 0 1-2.88 0 1.44 1.44 0 0 1 2.88 0z" />
          </svg>
        </a>
      </header>
    )}
  />
)

export default Header
